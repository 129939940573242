<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">ホーム画面の編集</h1>
        <banner-hint>
          システム標準のコンテンツパーツの他、登録した画像バナー＆ウィジェットパーツをホーム画面の好きな場所に表示することができます。<br />
          また、パーツの見出し・背景色・幅を調整して見た目をカスタマイズできます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="mb-5 py-7 px-6"
          elevation="1"
        >
          <v-row>
            <v-col cols="12">
              <num-instruction number="1">パーツ一覧からホーム画面に表示するパーツを選びます。</num-instruction>
              <v-select
                class="mx-2"
                v-model="selectedComps"
                chips
                label="パーツ一覧"
                multiple
                :items="masterComps"
                item-value="component_name"
                item-text="original_name"
                item-color="primary"
              ></v-select>
            </v-col>
          </v-row>

          <num-instruction number="2">↓のプレビューを確認しながら必要に応じてパーツ名（タイトル）の編集、パーツの並べ替えをします。</num-instruction>

          <v-form ref="form">
            <icon-info icon="lightbulb-on-outline" class="mt-3 mb-7 mx-1" :square="true">
              並べ替えは<v-icon small>mdi-gesture-double-tap</v-icon>をドラッグ＆ドロップで<br />
              表示名を空欄にするとHP上で見出しが消えます。
            </icon-info>

            <!-- パーツリスト -->
            <v-row no-gutters>
              <draggable
                class="d-flex flex-wrap"
                v-model="selectedComps"
                handle=".draggable"
                animation="100"
                delay="0"
              >
                <v-col :cols="component.is_half_width ? 6 : 12"
                  class="py-1 px-2"
                  v-for="(component, index) in selectedCompsObj"
                  :key="component.component_name"
                >
                  <v-card class="component py-3 px-5">
                    <v-row no-gutters>
                      <v-col cols="12" class="pr-3 d-flex">
                        <!-- アイコン＆バッジ -->
                        <v-sheet
                          class="draggable mr-5 align-self-center"
                          color="transparent"
                        >
                          <v-badge
                            class=""
                            :content="index + 1"
                            overlap
                            color="accent"
                          >
                            <v-avatar
                              color="primary"
                              size="40"
                            >
                              <v-icon>
                                mdi-gesture-double-tap
                              </v-icon>
                            </v-avatar>
                          </v-badge>
                        </v-sheet>

                        <v-sheet
                          class=" flex-grow-1"
                          color="transparent"
                        >
                          <v-row>
                            <!-- 表示名 -->
                            <v-col cols="12" class="py-0 mt-5 mb-2">
                              <v-text-field
                                v-model.trim="component.display_name"
                                required
                                placeholder="HP上の表示名"
                                :hint="`パーツ名：${component.original_name}`"
                                persistent-hint
                                counter="20"
                                :rules="[valiRules.max20]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                              <!-- 色指定 -->
                              <v-select
                                v-model="component.bg_color"
                                :items="bgColorList"
                                item-text="label"
                                item-value="value"
                                color="primary"
                                label="パーツ背景色"
                              ></v-select>
                            </v-col>
                            <v-col cols="6" class="py-0 d-flex justify-center align-center">
                              <!-- 幅 -->
                              <v-switch
                                class="my-2"
                                v-model="component.is_half_width"
                                inset
                                hide-details
                                label="幅を半分に"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </v-col>

                      <v-col>
                        <!-- ボタン -->
                        <div class="d-flex justify-end">
                          <v-btn
                            class="ml-2"
                            text
                            depressed small
                            color="primary"
                            @click="deleteAComp(index)"
                          >削除</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </draggable>
            </v-row>
          </v-form>

          <num-instruction number="3" class="mt-5">変更したら登録をお忘れなく。</num-instruction>

          <!-- 登録ボタン -->
          <div class="mt-3 ml-5">
            <v-btn
              depressed
              color="primary"
              @click="updateComps()"
            >変更を登録</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import draggable from "vuedraggable";
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import NumberInstruction from "@/components/_NumberInstruction.vue";
import IconInfo from "@/components/_IconInfo.vue";
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    draggable,
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'num-instruction': NumberInstruction,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      masterComps: [],
      selectedComps: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bgColorList: [
        {label: 'サブカラー', value: 'secondary'},
        {label: 'メインカラー', value: 'primary'},
        {label: '透明', value: 'transparent'},
      ],
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    selectedCompsObj() {
      const components = []
      //選択されたID配列からメニューのオブジェクト配列を作成
      this.selectedComps.map( compName => {
        this.masterComps.map( comp => {
          if (compName === comp.component_name) {
            components.push(comp)
          }
        })
      })
      return components
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await this.getCompMaster()

      await Promise.all([
        this.getSiteBanner(),
        this.getSiteWidget(),
      ])

      await this.getSiteHomeComps()

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // コンポーネントマスター取得
    //**************************************************
    getCompMaster() {
      return this.adminApi.getReqWithAuth('site-home-component-master/').then( masterComps => {
        if (!masterComps || !masterComps.length) {
          alert('パーツデータの取得に失敗しました')
          return
        }

        this.masterComps = masterComps.filter( master => {
          master.original_name = master.display_name
          master.is_half_width = false
          master.bg_color = 'secondary'
          return master.component_name !== 'image-banner' && master.component_name !== 'widget-tag'
        })
      })
    },

    //
    // API req: GET banner
    //
    getSiteBanner() {
      return this.adminApi.getReqWithAuth('site-banner/').then( results => {
        if (!results || !results.length) return

        results.map( banner => {
          this.masterComps.push({
            component_name: 'image-banner_' + banner.banner_id,
            original_name: banner.banner_name + '（バナー）',
            display_name: banner.banner_name,
            display_order: 0,
            is_half_width: false,
            bg_color: 'secondary'
          })
        })
      })
    },

    //
    // API req: GET widget
    //
    getSiteWidget() {
      return this.adminApi.getReqWithAuth('site-widget/').then( results => {
        if (!results || !results.length) return

        results.map( widget => {
          this.masterComps.push({
            component_name: 'widget-tag_' + widget.widget_id,
            original_name: widget.widget_name + '（ウィジェット）',
            display_name: widget.widget_name,
            display_order: 0,
            is_half_width: false,
            bg_color: 'secondary'
          })
        })
      })
    },

    //**************************************************
    // API req: GET home component
    //**************************************************
    getSiteHomeComps() {
      return this.adminApi.getReqWithAuth('site-home-component/').then( results => {
        if (!results || !results.length) return

        results.map( homeComp => {
          this.masterComps.map( master => {
            // マスターに代入
            if (master.component_name === homeComp.component_name) {
              master.display_name = homeComp.display_name
              master.is_half_width = homeComp.is_half_width
              master.bg_color = homeComp.bg_color
              // selected id配列も作成
              this.selectedComps.push(master.component_name)
            }
          })
          // this.homeComps.push(homeComp)
        })
      })
    },

    //**************************************************
    //更新
    //**************************************************
    updateComps() {
      if (!this.selectedCompsObj.length || !this.$refs.form.validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const payload = JSON.stringify(this.selectedCompsObj)

      this.adminApi.apiReqWithData('POST', 'update/site-home-component/', payload).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteAComp(index) {
      this.selectedComps.splice(index, 1)

      this.snackbar = {...{color:'info', message: "一番下のボタンで変更を登録してください", open: true}}
    },
  }
}
</script>

<style scoped>
.theme--light.component {
  background-color: var(--content-bg-diff);
}
.theme--dark.component {
  background-color: var(--content-bg-dark-diff);
}
</style>
